@use "./variables" as *;

@mixin Phone {
  @media screen and (min-width: 320px) {
    @content;
  }
}

@mixin Tablet {
  @media screen and (min-width: 768px) {
    @content;
  }
}

@mixin Desktop {
  @media screen and (min-width: 1280px) {
    @content;
  }
}

@mixin Button-Text {
  font-family: IBMPlexMono;
  font-size: 1rem;
  line-height: 120%;
  font-weight: 600;
  color: $white;

  @include Tablet {
    font-size: 1.125rem;
  }
}

@mixin P-Body {
  font-family: IBMPlexMono;
  font-size: 1.125rem;
  line-height: 120%;
  font-weight: 300;
  color: $white;

  @include Tablet {
    font-size: 1.125rem;
  }

  @include Desktop {
    font-size: 1.25rem;
  }
}

@mixin H3-Header {
  font-family: IBMPlexMono;
  font-size: 1.25rem;
  line-height: 110%;
  font-weight: 600;
  color: $white;

  @include Tablet {
    font-size: 1.5rem;
  }

  @include Desktop {
    font-size: 1.75rem;
  }
}

@mixin H2-Subtitle {
  font-family: IBMPlexMono;
  font-size: 1.4375rem;
  line-height: 120%;
  font-weight: 700;
  color: $white;

  @include Tablet {
    font-size: 1.75rem;
  }

  @include Desktop {
    font-size: 2rem;
  }
}

@mixin H1-Title {
  font-family: Heavitas;
  font-size: clamp(1.6875rem, 8vw, 4.3125rem);
  line-height: 100%;
  font-weight: 400;
  color: $white;
}
