@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/variables" as *;

.blog-page {
  background-color: $darker-gray;
  min-height: 100vh;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 20px;
  padding: 0 20px;

  &__main {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 40px;
    max-width: 768px;
  }

  &__container {
    display: flex;
    flex-direction: row;
    justify-content: center;
    gap: 20px;

    &--title {
      gap: 10px;
      flex-direction: column;
    }

    &-top {
      gap: 20px;
      flex-direction: column;
      align-items: center;
    }
    &-right {
      display: flex;
      gap: 20px;
      flex-direction: column;
      max-width: 500px;
    }
    &-left {
      display: flex;
      flex-direction: row;
      height: fit-content;
      align-items: center;
      width: 20%;
      gap: 10px;
    }

    &-time {
      @include Button-Text;
    }
    &-link {
      @include H2-Subtitle;
      text-decoration: underline $white;
    }

    &-line {
      border: 1px solid $white;
      width: 100%;
      height: 1px;
    }
  }

  &__title {
    @include H1-Title;
  }
  &__underline {
    width: 100%;
    height: 15px;
    border-radius: 10px;
    border: 4px solid rgba(186, 189, 215, 1);
  }

  &__button {
    padding: 16px 32px;
    background-color: $primary-blue;
    color: $white;
    border-radius: 5px;
    border: none;
    @include Button-Text;
  }
}
