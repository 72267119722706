* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

ol,
ul {
  list-style: none;
}

img,
svg {
  max-width: 100%;
  height: auto;
  display: block;
}

a {
  text-decoration: none;
  color: inherit;
}

html {
  min-width: 320px;
  min-height: 567px;
  scroll-behavior: smooth;
}
