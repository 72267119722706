@font-face {
  font-family: "Heavitas";
  src: url(../assets/fonts/Heavitas.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "IBMPlexMono";
  src: url(../assets/fonts/IBMPlexMono-Light.ttf) format("truetype");
  font-weight: 300;
}
@font-face {
  font-family: "IBMPlexMono";
  src: url(../assets/fonts/IBMPlexMono-Regular.ttf) format("truetype");
  font-weight: 400;
}

@font-face {
  font-family: "IBMPlexMono";
  src: url(../assets/fonts/IBMPlexMono-SemiBold.ttf) format("truetype");
  font-weight: 600;
}
@font-face {
  font-family: "IBMPlexMono";
  src: url(../assets/fonts/IBMPlexMono-Bold.ttf) format("truetype");
  font-weight: 700;
}
