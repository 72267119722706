@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/variables" as *;

.about-section {
  background-color: $almost-black;
  min-height: 100dvh;
  padding: 90px 20px;

  @include Tablet {
    background-color: unset;
  }

  @include Desktop {
    min-height: unset;
    display: flex;
    justify-content: center;
  }

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    max-width: 1240px;

    @include Tablet {
      background-color: $almost-black;
      border-radius: 20px;
      padding: 50px;
    }

    @include Desktop {
      gap: 40px;
    }
  }

  &__top-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    @include Desktop {
      align-items: flex-start;
    }
  }
  &__bottom-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    @include Desktop {
      flex-direction: row;
      gap: 40px;
    }
  }

  &__left-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    @include Tablet {
      height: 100%;
    }
    @include Desktop {
      max-width: 300px;
    }
  }

  &__right-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    @include Desktop {
      align-items: flex-start;
    }

    &-text {
      width: 100%;
      display: flex;
      flex-direction: column;
      gap: 20px;
      align-items: center;
    }
  }
  &__title {
    text-align: center;
    color: white;
    @include H2-Subtitle;
  }

  &__body {
    color: white;
    width: 100%;
    @include P-Body;

    &--blue {
      color: $purple;
    }
  }

  &__img {
    width: 50%;
    max-width: 200px;

    @include Desktop {
      width: 100%;
      max-width: 300px;
    }
  }
  &__img-container {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  &__list {
    display: flex;
    flex-direction: column;
    list-style-type: square;
    padding-left: 20px;
    width: 100%;
    gap: 10px;

    &-item {
      color: white;
      @include P-Body;
    }
  }

  &__hr {
    width: 100%;
    max-width: 80px;
    border-radius: 10px;
    border: 4px solid $light-gray-blue;
    margin-bottom: 20px;
    @include Desktop {
      max-width: 210px;
    }
  }
}
