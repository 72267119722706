@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/variables" as *;

.work-exp {
  min-height: calc(100dvh - 90px);
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  align-items: center;
  padding: 90px 20px;

  &__container {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 50px;
    max-width: 1240px;

    @include Desktop {
      background-color: $almost-black;
      border-radius: 20px;
    }
  }

  &__container-top {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;

    @include Desktop {
      align-items: flex-start;
    }
  }
  &__title {
    color: $white;
    @include H2-Subtitle;

    &--purple {
      color: $purple;
    }
  }

  &__hr {
    width: 100%;
    max-width: 80px;
    border-radius: 10px;
    border: 4px solid $light-gray-blue;
    margin-bottom: 20px;
    @include Desktop {
      max-width: 210px;
    }
  }

  &__container-bottom {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
  }

  &__job {
    display: flex;
    flex-direction: column;
    gap: 10px;

    &-container {
      display: flex;
      flex-direction: column;
    }

    &-name {
      @include Button-Text;
      color: $white;
    }
    &-date {
      @include Button-Text;
      color: $white;
    }
    &-role {
      @include H3-Header;
      color: $purple;
    }
    &-description {
      @include P-Body;
      color: $white;
    }
  }
}
