@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/variables" as *;

.header {
  width: 100%;
  height: 95px;

  &__img {
    display: none;

    @include Tablet {
      display: block;
    }
  }

  &__nav {
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    width: 100%;
    display: flex;
    justify-content: space-between;
    position: fixed;
    align-items: center;
    z-index: 1;
    top: 0;
    left: 0;
    padding: 20px;
  }
  &__menu {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 55px;
    height: 55px;
    background-color: $primary-blue;
    border: none;
    border-radius: 10px;

    &-img {
      height: 24px;
      width: 24px;
    }
  }

  &__name {
    min-width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 55px;
    border: none;
    border-radius: 10px;
    background-color: $almost-black;
    color: $light-gray-blue;
    padding: 12px 16px;
    @include H3-Header;

    @include Tablet {
      min-width: 280px;
      display: flex;
      gap: 10px;
      justify-content: center;
      align-items: center;
    }
  }

  &__right-container {
    display: flex;
    flex-direction: row;
    gap: 20px;
    align-items: center;
    height: 55px;

    @include Desktop {
      background-color: $almost-black;
      border-radius: 10px;
    }
  }

  &__list {
    display: none;

    @include Desktop {
      display: flex;
      gap: 20px;
      align-items: center;
      padding: 0 0 0 20px;
      height: 100%;
    }

    &-section {
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      width: 120px;
      padding: 12px 16px;
      color: $white;
      @include Button-Text;

      &--blue {
        background-color: $primary-blue;
      }

      &:hover {
        background-color: $primary-blue;
      }
    }
  }
}
