@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/variables" as *;

.connect-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 90px 20px;
  gap: 20px;
  width: 100%;

  @include Tablet {
    min-height: unset;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 20px;
    width: 100%;
    max-width: 768px;
    border-radius: 20px;

    @include Tablet {
      background-color: $almost-black;
      padding: 50px;
    }

    @include Desktop {
      align-items: flex-start;
    }
  }
  &__title {
    @include H2-Subtitle;
    color: $white;
    min-width: 270px;
    text-align: center;

    @include Desktop {
      text-align: unset;
    }
  }

  &__form {
    display: flex;
    gap: 20px;
    flex-direction: column;
    align-items: center;
    width: 100%;
    max-width: 500px;

    @include Tablet {
      align-items: flex-start;
      max-width: unset;
    }
  }

  &__input {
    @include Button-Text;
    background-color: $dark-gray;
    color: $white;
    border-radius: 5px;
    border: none;
    padding: 12px 16px;
    min-width: 270px;
    min-height: 55px;
    width: 100%;
    max-width: 500px;
    outline: none;

    &::placeholder {
      color: $light-gray-blue;
    }
  }

  &__textarea {
    @include Button-Text;
    background-color: $dark-gray;
    color: $white;
    border-radius: 5px;
    border: none;
    padding: 12px 16px;
    min-width: 270px;
    width: 100%;
    min-height: 200px;
    width: 100%;
    outline: none;
    resize: none;
    overflow: auto;
    max-width: 500px;

    &::placeholder {
      color: $light-gray-blue;
    }

    @include Tablet {
      max-width: unset;
    }
  }

  &__select {
    @include Button-Text;
    color: $white;
    border-radius: 5px;
    border: none;
    padding: 12px 50px 12px 16px;
    min-width: 180px;
    width: 100%;
    max-width: 300px;
    min-height: 55px;
    outline: none;
    align-self: flex-start;
    appearance: none;
    -webkit-appearance: none;
    background: url("../../assets/icons/DropDownArrow.svg") no-repeat right 16px
        center,
      $dark-gray;

    &::placeholder {
      color: $light-gray-blue;
    }
  }

  &__submit {
    @include Button-Text;
    min-width: 270px;
    min-height: 55px;
    background-color: $purple;
    border-radius: 5px;
    border: none;
    color: $white;
  }

  &__hr {
    width: 100%;
    max-width: 80px;
    border-radius: 10px;
    border: 4px solid $light-gray-blue;
    margin-bottom: 20px;
    @include Desktop {
      max-width: 210px;
    }
  }
}
