@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/variables" as *;

.hamburg-menu {
  width: 100%;
  height: 100%;
  background-color: $darker-gray;
  position: fixed;
  top: 0;
  z-index: 1;
  padding: 20px;

  @include Desktop {
    padding: 20px;
    width: 100%;
    height: 80px;
    background-color: transparent;
    display: flex;
    justify-content: flex-end;
  }

  &__list {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: flex-start;
    gap: 30px;

    @include Desktop {
      flex-direction: row;
      background-color: $almost-black;
      border-radius: 10px;
      max-width: 840px;
      height: 55px;
      align-items: center;
    }

    &-item {
      color: $light-gray-blue;
      @include H2-Subtitle;

      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 10px;
      width: 120px;
      padding: 12px 16px;
      color: $white;
      @include Button-Text;

      &:hover {
        background-color: $primary-blue;
      }

      &-link {
        @include Desktop {
          display: none;
        }
        &--display {
          display: block;
        }
      }
    }
  }

  &__button {
    background-color: transparent;
    border: none;

    @include Desktop {
      display: none;
    }

    &-icon {
      width: 20px;
    }
  }

  &__button-close {
    display: none;
    @include Desktop {
      display: flex;
      justify-content: center;
      align-items: center;
      width: 55px;
      height: 55px;
      background-color: $primary-blue;
      border: none;
      border-radius: 10px;
    }
    &-img {
      height: 24px;
      width: 24px;
    }
  }
}
