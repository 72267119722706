@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/variables" as *;

.hero-section {
  min-height: calc(100dvh - 90px);
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 20px;

  @include Desktop {
    align-items: center;
  }

  &__container {
    max-width: 1240px;
    position: relative;
    min-height: 376px;
    height: 80dvh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    background-color: $almost-black;
    justify-content: space-between;
    padding: 20px 0 0 0;

    @include Desktop {
      max-height: 730px;
      background-image: url("../../assets/images/Hero-Background-Desktop.png");
      background-repeat: no-repeat;
      background-position: center;
      background-size: contain;
      justify-content: flex-end;
    }
  }

  &__img-container {
    width: 100%;
    height: 100%;
    background-image: url("../../assets/images/HeroSection.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;

    animation: hoverEffect 3s ease-in-out infinite;

    @include Desktop {
      display: none;
    }
  }

  // Define the keyframes for the animation
  @keyframes hoverEffect {
    0%,
    100% {
      transform: translateY(0); // original position
    }
    50% {
      transform: translateY(-15px); // move up by 5px
    }
  }

  &__text {
    min-width: 260px;
    min-height: 27px;
    @include H1-Title;

    &-container {
      height: 35%;
      width: 100%;
      max-height: 120px;
      padding: 20px;
      border-radius: 20px 20px 0px 0px;
      border-width: 3px 0px 0px 0px;
      border-style: solid;
      border-color: $light-gray-blue;
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      background-color: $darker-gray;

      @include Desktop {
        justify-content: center;
      }
    }

    &--blue {
      color: $primary-blue;
    }
  }
}
