@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/variables" as *;

.project-section {
  min-height: 100dvh;
  width: 100%;
  padding: 90px 0;

  @include Tablet {
    padding: 90px 20px;
    display: flex;
    justify-content: center;
  }

  &__title {
    color: $white;
    @include H2-Subtitle;
  }
  &__hr {
    width: 100%;
    max-width: 80px;
    border-radius: 10px;
    border: 4px solid $light-gray-blue;
    margin-bottom: 20px;
    @include Desktop {
      max-width: 210px;
    }
  }
}

.project {
  &__container-top {
    display: flex;
    flex-direction: column;
    gap: 20px;
    align-items: center;
    @include Desktop {
      align-items: flex-start;
    }
  }

  &__container-bottom {
    @include Tablet {
      background-color: $almost-black;
      border-radius: 20px;
      overflow: hidden;

      display: flex;
      flex-direction: row;
    }
  }

  &__containers {
    max-width: 1240px;
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 20px;

    @include Tablet {
      justify-content: center;
      flex-direction: column;
      border-radius: 20px;
      overflow: hidden;
    }

    @include Desktop {
      height: fit-content;
      padding: 50px;
      background-color: $almost-black;
    }
  }
  &__container {
    width: 100%;
    position: relative;

    @include Tablet {
      min-height: 600px;
      max-height: 670px;
    }

    @include Desktop {
      min-height: unset;
      height: 100%;
    }
  }
  &__img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__text-container {
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 20px;
    gap: 40px;
    background-color: rgba(29, 27, 32, 0.8);

    @include Tablet {
      &:hover {
        background-color: rgba(29, 27, 32, 0.1);
        border: 3px solid #4f55d3;
        .project__title,
        .project__description {
          opacity: 0;
          visibility: hidden;
        }

        .project__link-arrow {
          visibility: unset;
        }
      }
    }
  }
  &__title {
    color: $white;
    @include H3-Header;
  }
  &__description {
    text-align: center;
    color: $white;
    @include P-Body;

    @include Tablet {
      height: 50%;
    }
  }

  &__link-arrow {
    visibility: hidden;
    position: absolute;
    top: 1%;
    right: 1%;
  }

  &-section__pagination {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }
}

.project-section .project-section__button {
  background-color: $primary-blue;
  color: $white;
  border: none;
  padding: 10px 20px;
  margin: 0 10px;
  cursor: pointer;
  border-radius: 5px;
  transition: background-color 0.3s;
}

.project-section__button:disabled {
  background-color: $light-gray-blue;
  cursor: not-allowed;
}

.project-section__button:hover:not(:disabled) {
  background-color: $primary-blue;
}
