@use "../../styles/mixins" as *;
@use "../../styles/typography" as *;
@use "../../styles/variables" as *;

.skill-section {
  width: 100%;
  min-height: 100dvh;
  padding: 90px 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: $almost-black;

  @include Tablet {
    background-color: unset;
    min-height: unset;
  }

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 40px;
    background-color: $almost-black;
    border-radius: 20px;
    width: 100%;
    max-width: 1240px;
    height: 100%;

    @include Tablet {
      padding: 50px;
    }
  }

  &__button {
    width: 148px;
    height: 15px;
    border-radius: 10px;
    border: 4px solid $light-gray-blue;
  }
  &__title {
    color: $white;
    @include H2-Subtitle;
  }
  &__list {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 40px;
    justify-content: center;
    align-items: center;

    @include Tablet {
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      flex-wrap: wrap;
    }

    &-title {
      color: $white;
      @include H3-Header;
      max-width: 200px;
      width: 65%;
      min-width: 160px;
      text-align: center;

      @include Tablet {
        width: 100%;
        max-width: unset;
      }

      @include Desktop {
        text-align: unset;
      }
    }

    &-item {
      color: $white;
      background-color: $dark-gray;
      padding: 0 10px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 65%;
      min-width: 160px;
      max-width: 180px;
      height: 55px;
      border-radius: 20px;

      @include Button-Text;
      text-align: center;
    }
  }

  &__top-container {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
    align-items: center;

    @include Desktop {
      align-items: unset;
    }
  }

  &__bottom-container {
    display: flex;
    width: 100%;
    gap: 40px;
    flex-direction: column;
    align-items: center;
  }

  &__hr {
    width: 100%;
    max-width: 80px;
    border-radius: 10px;
    border: 4px solid $light-gray-blue;
    margin-bottom: 20px;
    @include Desktop {
      max-width: 210px;
    }
  }
}
